<template>
  <Layout :top-bar-title="title" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <GroupsTable/>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import GroupsTable from '../components/tables/GroupsTable'
import sections from '../../dido/views/defaultNavigationDrawerSections'

export default {
  components: { GroupsTable, Layout },
  data () {
    return {
      sections: sections,
      title: this.$t('general.clients')
    }
  }
}
</script>

<style scoped>

</style>

<template>
<CrudTable
  v-bind="crudTableConfig"
  :storeModule="storeModule"
  :showAddItemsCustom="true"
  @save="onDistributorEdition"
  :adaptableHeight="true"
  @addItemsEvent="onDistributorCreate"
>

<template v-slot:[getLinkAttributes.path]="{ item }">
  <router-link :to="{ name: 'GroupsDetail', params: { id: item.id }}">
    {{ Lget(item, getLinkAttributes.name, '-')}}
  </router-link>
</template>
</CrudTable>
</template>
<script>
import Lset from 'lodash/set'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapActions, mapState } from 'vuex'
import Lget from 'lodash/get'

export default {
  name: 'GroupsTable',
  components: { CrudTable },

  data () {
    return {
      storeModule: 'groupsCatalog'
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    }),

    getLinkAttributes () {
      const linkAttributes = {}
      let value = this.crudTableConfig.headers?.length > 0 ? this.crudTableConfig.headers[0].value : ''

      linkAttributes['path'] = `item.${value}`
      linkAttributes['name'] = value
      return linkAttributes
    }
  },
  methods: {
    ...mapActions('groupsCatalog', ['reloadItemList', 'updateCurrentItem']),
    Lget: Lget,
    onDistributorEdition (data) {
      const updatedItem = Lset(data.item, data.header, data.value)

      this.$store
        .dispatch(`${this.storeModule}/postItem`, updatedItem)
        .then(
          () => this.reloadItemList()
        )
    },
    onDistributorCreate () {
      this.$router.push({
        name: 'GroupsDetail',
        params: { id: 'new' }
      })
    }
  }
}
</script>

<style scoped>

</style>
